import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {isEmpty} from "@core/utils/filter";

export default function feeloadchargeUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)
  const condition = ref({})
  const amount = ref(0)
  const amountChange = ref(0)
  const totalAmountChange = ref(0)

  // Table Handlers
  const tableColumns = [
    { key: 'charge_id', label: 'ID', sortable: true },
    { key: 'relational_no', label: '关联订单', sortable: true },
    { key: 'arrival_time', label: '单据日期', sortable: true },
    { key: 'order_type', label: '单据类型', sortable: true },
    //{ key: 'warehouse_id', label: '仓库ID', sortable: true },
    { key: 'from_warehouse_name', label: '调出仓库', sortable: true },
    { key: 'to_warehouse_name', label: '调入仓库', sortable: true },
    //{ key: 'company_id', label: '装卸队ID', sortable: true },
    { key: 'company_name', label: '装卸队名称', sortable: true },
    { key: 'qty', label: '数量', sortable: true },
    { key: 'rule_name', label: '计价方式', sortable: true },
    //{ key: 'rule_amount', label: '计价方式对应费用', sortable: true },
    //{ key: 'calculateqty', label: '计划份数', sortable: true },
    { key: 'total_amount', label: '装卸费小计', sortable: true },
    { key: 'total_amount_change', label: '调整金额', sortable: true },
    { key: 'after_change_total_amount', label: '调整后小计', sortable: true },
    { key: 'remark', label: '备注', sortable: true },
    //{ key: 'attachments', label: '素材包', sortable: true },
    { key: 'status', label: '结算单状态', sortable: true },
    //{ key: 'state', label: '启用', sortable: true },
    //{ key: 'create_time', label: '添加时间', sortable: true },
    //{ key: 'creator', label: '添加人', sortable: true },
    { key: 'update_time', label: '更新时间', sortable: true },
    { key: 'updator', label: '更新人', sortable: true },
    //{ key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('charge_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('feeloadcharge/search', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
        relational_no:condition.value.relational_no,
        order_type:condition.value.order_type,
        warehouse_id:condition.value.warehouse_id,
        item_rule_name:condition.value.rule_name,
        product_name:condition.value.product_name,
        company_id:condition.value.company_id,
        status:condition.value.status,
        search_create_time:condition.value.create_time,
        creator:condition.value.user_id,
        purchaseTeamId: isEmpty(condition.value.purchaseTeamId)?null:condition.value.purchaseTeamId.value
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals
        amount.value = data.ext.amount
        amountChange.value = data.ext.amountChange
        totalAmountChange.value = data.ext.totalAmountChange
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,
    condition,
    amount,
    amountChange,
    totalAmountChange,

    refetchData,

    // Extra Filters
  }
}
