<template>

  <div>

    <!-- Table Container Card -->
    <b-card>
      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(item_id)="data">
          #{{ data.item.id }}
        </template>

        <!--计价方式-->
        <template #cell(rule_name)="data">
          <div style="width: 130px">
            <v-select
                @input="updateItemCharge(data.item)"
                :options="getCodeOptions('fee_load_setting_type')"
                class="select-size-sm"
                v-model="data.item.rule_name"
                :reduce="option => option.value"
            />
          </div>
        </template>

        <template #cell(purchaseTeam)="data">
          {{ getCodeLabel('purchase_team', data.item.ext.purchaseTeam) }}
        </template>

      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import feeloadchargeitemUseList from './feeloadchargeitemUseList'
import feeloadchargeitemStore from './feeloadchargeitemStore'
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  props: {
    charge_id: {
      type: Number,
      default: 0
    },
  },
  setup(props) {
    const toast = useToast()
    // Register module
    if (!store.hasModule('feeloadchargeitem')) store.registerModule('feeloadchargeitem', feeloadchargeitemStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('feeloadchargeitem')) store.unregisterModule('feeloadchargeitem')
    })

    const updateItemCharge = function (data) {
      if (data.rule_name == null) {
        toast.error('请选择计价方式')
        refetchData()
        return false
      }
      store.dispatch('feeloadchargeitem/updateItemCharge', data).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
          //刷新主表
          this.$emit('itemChange')
        } else {
          toast.error(res.data.data)
          refetchData()
        }
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = feeloadchargeitemUseList(props)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      updateItemCharge,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
