<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>

            <div class="demo-spacing-0 mr-1" style="margin-left: 10px">
              <b-alert variant="primary" show>
                <div class="alert-body">
                  <span><strong>装卸费总金额:{{ totalAmountChange }}</strong> </span>
                </div>
              </b-alert>
            </div>
          </b-col>
          <!-- 搜索、导出按钮 -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="advanced_search"
                  class="mr-1"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="updateStatus(2)"
                  class="mr-1"
              >
                <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量确认</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="updateStatus(3)"
                  class="mr-1"
                  v-if="user.role_id === 14 || user.role_id === 17 || user.role_id === 18"
              >
                <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                />
                <span class="align-middle">批量审核</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="updateByCondition"
                  class="mr-1"
              >
                <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                />
                <span class="align-middle">更新单价</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  @click="selectAllRows"
                  class="mr-1"
              >
                <feather-icon
                    icon="CheckIcon"
                    class="mr-50"
                />
                <span class="align-middle">全部勾选</span>
              </b-button>
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  @click="clearSelected"
                  class="mr-1"
              >
                <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                />
                <span class="align-middle">清空勾选</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--关联编号-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="关联单号"
                    label-for="relational_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="relational_no"
                      size="sm"
                      v-model="condition.relational_no"
                      placeholder="请输入关联单号"
                  />
                </b-form-group>
              </b-col>
              <!--单据类型-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="单据类型"
                    label-for="order_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="order_type"
                      :options="order_type_select"
                      v-model="condition.order_type"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择单据类型"
                  />
                </b-form-group>
              </b-col>
              <!--仓库名称-->
              <b-col md="3">
                <modal-select
                    label="仓库名称"
                    type="input"
                    v-on:change="fromChildren($event,['warehouse_name','warehouse_id'],condition)"
                    :params="['warehouse_name','warehouse_id']"
                    modalName="仓库"
                    placeholder="点击选择仓库"
                    v-model="condition.warehouse_name"
                >
                </modal-select>
              </b-col>
              <!--计价方式-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="计价方式"
                    label-for="rule_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="rule_name"
                      :options="getCodeOptions('fee_load_setting_type')"
                      v-model="condition.rule_name"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择计价方式"
                  />
                </b-form-group>
              </b-col>
              <!--商品名称-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="商品名称"
                    label-for="product_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="product_name"
                      size="sm"
                      v-model="condition.product_name"
                      placeholder="请输入商品名称"
                  />
                </b-form-group>
              </b-col>
              <!--装卸队-->
              <b-col md="3">
                <modal-select
                    label="装卸队"
                    type="input"
                    v-on:change="fromChildren($event,['company_name','company_id'],condition)"
                    :params="['company_name','company_id']"
                    modalName="装卸队"
                    placeholder="点击选择装卸队"
                    v-model="condition.company_name"
                >
                </modal-select>
              </b-col>
              <!--结算状态-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="结算状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('fee_load_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      :reduce="option => option.value"
                      placeholder="请选择结算状态"
                  />
                </b-form-group>
              </b-col>
              <!--添加人-->
              <b-col md="3">
                <modal-select
                    label="创建人"
                    type="input"
                    v-on:change="fromChildren($event,['full_name','user_id'],condition)"
                    :params="['full_name','user_id']"
                    modalName="创建人"
                    placeholder="点击选择创建人"
                    v-model="condition.full_name"
                >
                </modal-select>
              </b-col>
              <!--添加时间-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="添加时间"
                    label-for="create_time"
                    label-size="sm"
                >
                  <flat-pickr
                      v-model="condition.create_time"
                      class="form-control"
                      :config="rangeConfig"
                      placeholder="请选择添加时间"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  cols="12"
                  md="3"
              >
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="销售权:"
                    label-for="status"
                    label-size="sm"
                >
                  <v-select
                      :options="getCodeOptions('purchase_team')"
                      v-model="condition.purchaseTeamId"
                      name="status"
                      class="select-size-sm"
                      placeholder="请选择销售权"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          selectable
          selected-variant="info"
          select-mode="multi"
          @row-selected="onRowSelected"
      >


        <template #row-details="row">
          <fee-load-charge-item-list :charge_id="row.item.id" v-on:itemChange="refetchData"></fee-load-charge-item-list>
        </template>
        <!-- Columns -->
        <template #cell(charge_id)="data">
          <b-form-checkbox v-model="data.detailsShowing"
                           name="check-button"
                           @change="data.toggleDetails"
                           button-variant="default"
                           size="sm"
                           button>
            <feather-icon
                icon="MinusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==true"
            />
            <feather-icon
                icon="PlusSquareIcon"
                size="16"
                class="align-middle text-body"
                v-show="data.detailsShowing==false"
            />
          </b-form-checkbox>
          #{{ data.item.id }}
        </template>

        <!--关联订单-->
        <template #cell(relational_no)="data">
          [#{{ data.item.relational_id }}]<br/>
          {{ data.item.relational_no }}
        </template>

        <!--调出仓库-->
        <template #cell(from_warehouse_name)="data">
          {{ data.item.ext.from_warehouse_name }}
        </template>

        <!--调入仓库-->
        <template #cell(to_warehouse_name)="data">
          {{ data.item.ext.to_warehouse_name }}
        </template>

        <!--单据日期-->
        <template #cell(arrival_time)="data">
          {{ toDate(data.item.arrival_time) }}
        </template>

        <!--关联订单分类-->
        <template #cell(order_type)="data">
          {{ getCodeLabel('stock_log_order_type', data.item.order_type) }}
        </template>

        <!--装卸队名称-->
        <template #cell(company_name)="data">
          <div style="width: 130px">
            <modal-select
                type="input"
                :showLabel=false
                v-on:change="toUpdate($event,['company_name','company_id'],data.item)"
                :params="['company_name','company_id']"
                :warehouseId="data.item.warehouse_id"
                modalName="装卸队"
                placeholder="点击选择装卸队"
                v-model="data.item.company_name"
            >
            </modal-select>
          </div>
        </template>

        <!--计价方式-->
        <template #cell(rule_name)="data">
          <div style="width: 140px">
            <v-select
                :options="getCodeOptions('fee_load_setting_type')"
                class="select-size-sm"
                v-model="data.item.rule_name"
                :reduce="option => option.value"
                @input="updateCharge(data.item,true)"
                placeholder="请选择计价方式"
            />
          </div>
        </template>

        <!--调整金额-->
        <template #cell(total_amount_change)="data">
          <div style="width: 60px">
            <b-form-input
                @change="updateCharge(data.item,false)"
                size="sm"
                type="number"
                v-model="data.item.total_amount_change"
            />
          </div>
        </template>

        <!--调整后小计-->
        <template #cell(after_change_total_amount)="data">
          {{ addNumber(data.item.total_amount, data.item.total_amount_change) }}
        </template>

        <!--结算单状态-->
        <template #cell(status)="data">
          <b-badge pill :variant="`light-${getCodeColor('fee_load_status', data.item.status)}`">
            {{ getCodeLabel('fee_load_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- 备注 -->
        <template #cell(remark)="data">
          <div @click="remarkModal(data.item)" style="width: 50px">
            <feather-icon icon="Edit3Icon"/>
          </div>
          <span v-if="data.item.remark">
            {{ data.item.remark.substring(0, 3) }}
            <span v-if="data.item.remark.length>3">...</span>
          </span>
        </template>

        <!--添加时间-->
<!--        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>-->

        <!--添加人-->
<!--        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>-->

        <!--更新时间-->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人-->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <template v-slot:custom-foot="data">
          <b-tr>
            <b-td :colspan="1" variant="primary">
              <span><strong>合计</strong></span>
            </b-td>
            <b-td :colspan="8" variant="primary">
              <span><strong></strong></span>
            </b-td>
            <b-td :colspan="2" variant="primary">
              <span><strong>{{ amount }}</strong></span>
            </b-td>
            <b-td :colspan="5" variant="primary">
              <span><strong>{{ amountChange }}</strong></span>
            </b-td>
          </b-tr>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

      <b-modal
          id="remarkModal"
          ok-only
          ok-title="确认"
          @ok="saveRemark"
          centered
          size="lg"
          :title="'请填写备注'"
      >
        <b-col md="12">
          <b-row>
            <b-col md="12">
              <b-form-group
                  label-cols="1"
                  label-cols-lg="1"
                  label="备注"
                  label-for="remark"
                  class="mb-1"
              >
                <b-form-input
                    id="remark"
                    size="sm"
                    v-model="remark"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-modal>

      <!--  loading  -->
      <div v-show="loadingModal">
        <xy-loading :placeholder="loadingPlaceholder"></xy-loading>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  addNumber, isEmpty
} from '@core/utils/filter'
import feeloadchargeUseList from './feeloadchargeUseList'
import feeloadchargeStore from './feeloadchargeStore'
import {useToast} from "vue-toastification/composition";
import FeeLoadChargeItemList from "@/views/apps/feeloadchargeitem/FeeLoadChargeItemList";
import ModalSelect from "@/views/components/modal/ModalSelect";
import Ripple from "vue-ripple-directive";
import {getUserData} from "@/auth/utils";
import XyLoading from "@/views/components/xy/XyLoading";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    FeeLoadChargeItemList,
    ModalSelect,
    XyLoading,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isShowCard: false,
      remark: '',
      remarkId: '',
      selected: [],
      rangeConfig: {
        mode: 'range',
        maxDate: 'today',
      },
      user: {},
      order_type_select: [
        {label: '前置销售单', value: '16'},
        {label: '采购入库通知单', value: '1'},
        {label: '库存调拨通知单(出库)', value: '5'},
        {label: '库存调拨通知单(入库)', value: '6'}
      ],
      loadingModal: false,
      loadingPlaceholder: "正在更新单价,请稍等..."
    }
  },
  setup() {
    // Use toast
    const toast = useToast()

    // Register module
    if (!store.hasModule('feeloadcharge')) store.registerModule('feeloadcharge', feeloadchargeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('feeloadcharge')) store.unregisterModule('feeloadcharge')
    })

    const changeState = function (data) {
      const state = 1 - data.state
      store.dispatch('feeloadcharge/state', {id: data.id, state: state}).then(res => {
        data.state = state
        refetchData()
      })
    }

    const updateCharge = function (data, updateMainRule) {
      if (data.status == 3) {
        toast.error("已结算状态下不能修改")
        refetchData()
        return false
      }
      let action = 'feeloadcharge/'
      if (updateMainRule) {
        action += 'updateMainRule'
      } else {
        action += 'updateCharge'
      }
      store.dispatch(action, data).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
        } else {
          toast.error(res.data.data)
        }
        refetchData()
      })

    }

    const fromChildren = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()
    }

    const toUpdate = function (params, modal, item) {
      for (let i = 0; i < modal.length; i++) {
        item[modal[i]] = params == null ? null : params[modal[i]]
      }
      this.$forceUpdate()

      this.updateCharge(item, false)
    }

    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData();
    }

    const exportExcel = function () {
      const params = {
        relational_no: condition.value.relational_no,
        order_type: condition.value.order_type,
        warehouse_id: condition.value.warehouse_id,
        item_rule_name: condition.value.rule_name,
        product_name: condition.value.product_name,
        company_id: condition.value.company_id,
        status: condition.value.status,
        search_create_time: condition.value.create_time,
        creator: condition.value.user_id,
        purchaseTeamId: isEmpty(condition.value.purchaseTeamId) ? null : condition.value.purchaseTeamId.value
      }
      store.dispatch('feeloadcharge/exportExcel', params).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '装卸费结算' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const remarkModal = function (data) {
      this.remark = data.remark
      this.remarkId = data.id
      this.$bvModal.show('remarkModal')
    }

    const saveRemark = function () {
      store.dispatch('feeloadcharge/save', {charge_id: this.remarkId, remark: this.remark}).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
          refetchData()
        } else {
          toast.error(res.data.data)
        }
      })
    }

    const onRowSelected = function (items) {
      this.selected = items
    }

    const selectAllRows = function () {
      this.$refs.refListTable.selectAllRows()
    }

    const clearSelected = function () {
      this.$refs.refListTable.clearSelected()
    }

    const updateStatus = function (status) {
      if (this.selected.length < 1) {
        toast.error('至少选中一条')
        return false
      }
      let ids = [];
      if (status === 2) {
        //批量确认
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i].status != 1) {
            toast.error('勾选的结算单状态须为待确认')
            return false
          }
          ids.push(this.selected[i].id)
        }
      }
      if (status === 3) {
        //批量审核
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i].status != 2) {
            toast.error('勾选的结算单状态须为待审核')
            return false
          }
          ids.push(this.selected[i].id)
        }
      }
      this.$swal({
        title: '确定提交吗',
        text: '提交后结算单将不可修改',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          store.dispatch('feeloadcharge/updateStatusByIds', {ids: ids.toString(), status: status}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已提交!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })

    }

    const updateByCondition = function () {
      if (this.selected.length < 1) {
        toast.error('至少选中一条')
        return false
      }
      let ids = [];
      //批量确认
      for (let i = 0; i < this.selected.length; i++) {
        ids.push(this.selected[i].id)
      }

      this.loadingModal = true
      store.dispatch('feeloadcharge/updateByCondition', {ids:ids.toString()}).then(res => {
        if (res.data.code == 0) {
          toast.success(res.data.data)
          refetchData()
        } else {
          toast.error(res.data.data)
        }
        this.loadingModal = false
      })
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,
      amount,
      amountChange,
      totalAmountChange,

      // UI
    } = feeloadchargeUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      changeState,
      updateCharge,
      fromChildren,
      condition,
      advanced_search,
      searchByCondition,
      resetCondition,
      exportExcel,
      saveRemark,
      remarkModal,
      toUpdate,
      onRowSelected,
      selectAllRows,
      clearSelected,
      updateStatus,
      amount,
      amountChange,
      totalAmountChange,
      updateByCondition,
      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      addNumber,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
